import ScrollManager from '../utils/scroll-manager.mjs';
import { firstAnscestorOrDefault } from '../utils/dom-utils.mjs';

/**
 * @type {IntersectionObserver}
 */
let intersectionObserver = null;

/**
 * @type {ScrollManager}
 */
let scrollManager = null;

export default (
  rootElement = document.body,
  scrolledElement = document,
  measuredElement = document.documentElement) => {
  const containerElements = rootElement.querySelectorAll('.anchor-container');

  if(containerElements.length === 0) {
    return;
  }

  if(containerElements.length > 1) {
    throw new Error('Multiple anchor containers found, this is not supported.');
  }

  if(!!intersectionObserver || !!scrollManager) {
    destroy();
  }

  const containerElement = containerElements[0];
  initAnchorContainer(containerElement, scrolledElement, measuredElement);
};

export let destroy = () => {
  if(intersectionObserver) {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  }

  if(scrollManager) {
    scrollManager.destroy();
    scrollManager = null;
  }
};

const initAnchorContainer = (containerElement, scrolledElement, measuredElement) => {
  const anchorList = containerElement.querySelector('.anchor-list');
  if(!anchorList) {
    console.warn('Anchor container is missing anchor list.', containerElement);
    return;
  }

  anchorList.childNodes.forEach(anchorItem => anchorItem.remove());

  const anchorItems = [...containerElement.querySelectorAll('[data-anchor-target]')];
  if(anchorItems.length === 0) {
    console.info('Anchor container is missing anchor items.', containerElement);
    return;
  }

  const anchorLinks = anchorItems
    .filter(anchorItem => {
      if(!anchorItem.id) {
        return false;
      }

      const anchorText = anchorItem.dataset.anchorTarget || anchorItem.textContent;
      if(!anchorText) {
        return false;
      }

      return true;
    })
    .map(anchorItem => {
      const anchorLink = document.createElement('a');
      anchorLink.href = '#' + anchorItem.id;
      anchorLink.textContent = anchorItem.dataset.anchorTarget || anchorItem.textContent;

      return { anchorItem, anchorLink };
    })
    .map(({ anchorItem, anchorLink }) => {
      const anchorListItem = document.createElement('li');
      anchorListItem.id = anchorItem.id + '-anchor-link';
      anchorListItem.appendChild(anchorLink);

      return anchorListItem;
    });

  const list = document.createElement('ol');
  anchorLinks.forEach(anchorLink => list.appendChild(anchorLink));
  anchorList.appendChild(list);

  makeSticky(scrolledElement, measuredElement, anchorList);
  initIntersectionObserver(containerElement, anchorItems);
};

const makeSticky = (scrolledElement, measuredElement, stickyElement) => {
  scrollManager = new ScrollManager(scrolledElement, measuredElement);

  // 2024-08-26 KJ We are calculating the position of when to "get sticky", so if
  // the element is already sticky we need to return it to its original position
  // before we make the calculation.
  // This will trigger scroll events on the page though which isn't ideal but will
  // have to do for now.

  const alreadySticky = window.getComputedStyle(stickyElement).position === 'fixed';
  if(alreadySticky) {
    stickyElement.style.position = 'static';
  }

  const breakpoint = stickyElement.getBoundingClientRect().top + measuredElement.scrollTop;

  if(alreadySticky) {
    stickyElement.style.position = null;
  }

  scrollManager.addEventListener('scroll', /** @param {CustomEvent} scrollEvent */ (scrollEvent) => {
    const {
      scrollPosition,
      scrollDirection,
      scrolledDistance
    } = scrollEvent.detail;

    if(scrollPosition > breakpoint) {
      stickyElement.classList.add('sticky');
    } else if(scrollPosition <= breakpoint) {
      stickyElement.classList.remove('sticky');
    }

    const showAfter = 70; // These needs to be synced with the main menu
    const hideAfter = 70;

    if(scrollDirection === 'up' && scrolledDistance > showAfter) {
      stickyElement.classList.add('offset-sticky-header');
    }

    if(scrollDirection === 'down' && scrolledDistance > hideAfter) {
      stickyElement.classList.remove('offset-sticky-header');
    }
  });
};

const initIntersectionObserver = (containerElement, anchorItems) => {
  intersectionObserver = new IntersectionObserver(
    /** @param {IntersectionObserverEntry[]} entries */ (entries) => {
      entries.forEach(entry => {
        let anchorItem = entry.target;
        if(anchorItem.hasAttribute('data-anchor-target-container')) {
          anchorItem = anchorItem.querySelector('[data-anchor-target]');
          if(!anchorItem) {
            console.warn('Found anchor container without anchor target.', entry.target);
            return;
          }
        }

        const anchorLinkId = anchorItem.id + '-anchor-link';
        const anchorLink = containerElement.querySelector(`#${anchorLinkId}`);
        if(!anchorLink) {
          console.warn('Found anchor item without anchor link.', anchorItem);
          return;
        }

        if(entry.isIntersecting) {
          anchorLink.classList.add('current-anchor');
        } else {
          anchorLink.classList.remove('current-anchor');
        }

        updateAnchorListScrollPosition(anchorLink);
      });
    },
    {
      rootMargin: `-25% 0px -75% 0px`
    });

  for(const anchorItem of anchorItems) {
    const anchorContainer = firstAnscestorOrDefault(anchorItem, e => e.hasAttribute('data-anchor-target-container'));
    intersectionObserver.observe(anchorContainer ?? anchorItem);
  }
};

const updateAnchorListScrollPosition = anchorLink => {
  const anchorList = firstAnscestorOrDefault(anchorLink, e => e.classList.contains('anchor-list'));
  if(!anchorList) {
    console.warn('Found anchor link outside of anchor list.', anchorLink);
    return;
  }

  const actualList = anchorList.querySelector('ol');
  if(!actualList) {
    console.warn('Anchor list is missing an actual list.', anchorList);
    return;
  }

  const currentAnchors = actualList.querySelectorAll('.current-anchor');
  if(currentAnchors.length === 0) {
    return;
  }

  const anchor = currentAnchors[0];
  const anchorOffsetLeft = anchor.offsetLeft - (anchor.clientWidth / 2);

  actualList.scrollTo?.({ left: anchorOffsetLeft, behavior: 'auto' });
};
