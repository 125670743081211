import initBiddingDialog from './estate-page/bidding-dialog.mjs';
import initBookShowingDialog from './estate-page/book-showing-dialog.mjs';
import initFactsSection from './estate-page/facts.mjs';
import initImageDialog from './estate-page/image-dialog.mjs';
import initProjectEstates from './estate-page/project-estates.mjs';
import initSimilarEstates from './estate-page/similar-estates.mjs';

/**
 * @param {Element} rootElement
 */
export default (rootElement = document.body) => {
  const pageElement = rootElement?.querySelector('#estate-page');

  if(!pageElement) {
    return;
  }

  initBiddingDialog();
  initBookShowingDialog();
  initFactsSection(pageElement);
  initImageDialog();
  initProjectEstates(pageElement);
  initSimilarEstates(pageElement);
};
