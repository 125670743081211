import { getPageLanguage } from '../../utils/get-page-language.mjs';
import applyAnchorContainer from './../../page-scripts/anchor-container.mjs';
import configuration from '../../configuration.mjs';

/**
 * @param {Element} rootElement
 */
export default async(rootElement = document.body) => {
  const similarEstatesContainer = rootElement.querySelector('#similar-estates-container');
  if(!similarEstatesContainer) {
    return;
  }

  const culture = getPageLanguage();
  const estateShortId = configuration.estate.shortId;

  const url = `/api/${culture}/estate/${estateShortId}/similar-estates`;
  const response = await fetch(url);
  if(!response.ok) {
    return;
  }

  const html = await response.text();

  similarEstatesContainer.innerHTML = html;

  applyAnchorContainer();
};
