import { getPageLanguage } from '../../utils/get-page-language.mjs';
import configuration from '../../configuration.mjs';

/**
 * @param {Element} rootElement
 */
export default async(rootElement = document.body) => {
  const projectEstatesContainer = rootElement.querySelector('#project-estates-container');
  if(!projectEstatesContainer) {
    return;
  }

  const showAllProjectestatesButton = projectEstatesContainer.querySelector('.show-all-estates-button');
  if(!!showAllProjectestatesButton) {
    showAllProjectestatesButton.addEventListener('click', () => {
      projectEstatesContainer.classList.add('show-all-estates');
    });
  }

  const culture = getPageLanguage();
  const estateShortId = configuration.estate.shortId;

  const url = `/api/${culture}/estate/${estateShortId}/project-estates`;
  const response = await fetch(url);
  const items = await response.json();

  const table = projectEstatesContainer.querySelector('table');
  const tableBody = table.querySelector('tbody');

  for(const item of items) {
    const row = document.createElement('tr');
    let html = `
      <td>${item.itemNumber || ''}</td>
      <td>${item.livingArea || ''}</td>
      <td>${item.numberOfRooms || ''}</td>
      <td>${item.monthlyFee || ''}</td>
      <td>${item.price || ''}</td>
      <td>${item.status || ''}</td>`;

    if(!!item.url && !!item.linkText) {
      html += `<td><a href="${item.url}">${item.linkText}</a></td>`;
    } else {
      html += '<td></td>';
    }

    row.innerHTML = html;

    tableBody.appendChild(row);
  }

  if(items.length > 5) {
    showAllProjectestatesButton.classList.add('show');
  }
};
